import { Component, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { SmsManager } from '@byteowls/capacitor-sms';
import { MenuController } from '@ionic/angular';
import { Usuario } from './Models/usuario.interface';
import { AuthenticationService } from './services/authentication.service';
import { LoginService } from './services/login.service';


@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  public isDarkTheme: boolean = false;
  usuario!: Usuario;
  nombre!: string;
  apellido!: string;
  iosOrAndroid: boolean;
  datosUsuario: any = [];

  constructor(private menu: MenuController, private renderer: Renderer2, public usuarioService: LoginService, private authService: AuthenticationService, private router: Router) {
    this.usuarioService.usuario.subscribe(res =>{
      this.usuario = res;
      if (this.usuario) {
        this.checalo();
      }
      
      
    });
    // const info: DeviceInfo = await Device.getInfo();
    // this.iosOrAndroid = (info.platform === "android" || info.platform === "ios");
    // this.sendSms() 
  }

  openFirst() {
    this.menu.enable(true, 'first');
    this.menu.open('first');
  }

  close(){
    this.menu.close();
    this.usuarioService.logout();
    this.authService.logout();
    this.router.navigateByUrl('/login', { replaceUrl: true });
  }

//   sendSms() {
//     const numbers: string[] = ["+52 639 1061558"];
//     SmsManager.send({
//         numbers: numbers,
//         text: "hola compadre, le mando este mensaje",
//     }).then(() => {
//         // success
//     }).catch(error => {
//         console.error(error);
//     });
// }

  closen(){
    this.menu.close();
  }


  checalo() {
    this.usuarioService.checaUsuario(this.usuario.usuario).subscribe(data => {
      this.datosUsuario = data;


      this.nombre = this.datosUsuario.nombre;
      this.apellido = this.datosUsuario.apellido;
     
    })
  }


}
