import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AlertController, LoadingController, ModalController } from '@ionic/angular';
import { Registro } from 'src/app/Models/registro.interface';
import { RegistroService } from 'src/app/services/registro.service';

@Component({
  selector: 'app-modal-registro',
  templateUrl: './modal-registro.page.html',
  styleUrls: ['./modal-registro.page.scss'],
})
export class ModalRegistroPage implements OnInit {

  isSubmitted = false;
  datosDynamics: any = [];
  customerID!: string;
  correo!: string;
  regis: Registro;
  isLoading = false;
  newsActive: any;
  newsletter: number;
  regisnew: any;
  facturame = false;
  UsoCFDI:any;
  usocfdi:any;
  regimenFiscal:any;

  constructor(
    private modalCtrl: ModalController,
    public formBuilder: UntypedFormBuilder,
    public alertController: AlertController,
    public loadingController: LoadingController,
    public router: Router,
    public registroService: RegistroService
  ) { }

  regisForm: UntypedFormGroup;

  ngOnInit() {
    this.regisForm = this.formBuilder.group({
      nombre:    ['', [Validators.required, Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$'), Validators.minLength(2), Validators.maxLength(36)]],
      apellidos: ['', [Validators.required, Validators.pattern('^[A-Za-zñÑáéíóúÁÉÍÓÚ ]+$'), Validators.minLength(5), Validators.maxLength(36)]],
      rfc:       ['', [Validators.pattern('^[A-Za-z0-9]+$'), Validators.minLength(12), Validators.maxLength(13)]],
      email:     ['', [Validators.required, Validators.pattern('[A-Za-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,3}$')]],
      password:  ['', [Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{7,}')]],
      /* https://localcoder.org/how-to-validate-password-strength-with-angular-5-validator-pattern  */
      /* 
      - At least 8 characters in length
      - Lowercase letters
      - Uppercase letters
      - Numbers
      - Special characters
      */
      news:      [false],
      priv:      [false, [Validators.pattern('true')]],
      term:      [false, [Validators.pattern('true')]]
    });
  }

  register(){
    this.presentLoading()
    this.isSubmitted = true;
    if (this.regisForm.invalid) {
      //return;
      
      return;
    }
      this.regis = this.regisForm.value;
      this.newsActive = this.regis.news;
      //console.log(this.newsActive);
      if (this.newsActive == true) {
        this.newsletter = 1;
      } else {
        this.newsletter = 0;
      }

      this.regis.newsletter = this.newsletter;

      
      //this.SpinnerService.show();
      this.registroService.registroUsuario(this.regis).subscribe(response =>{
        console.log(response)
        if(response == 1){
          
          this.addUserDynamics(this.regis);
          this.dismiss();
          
        }
        if(response == 2){
          this.dismiss();
          //this.errorNotificationCorreo();
          //this.SpinnerService.hide();
          
        }
      
      });
    
    
  }

  facturado(){
    if (this.facturame == false) {
      this.facturame = true;
      
    }else{
      this.facturame = false;
    }

  }

  async presentLoading() {
    this.isLoading = true;
    return await this.loadingController.create({
      cssClass: 'my-custom-class',
      message: 'Registrando Usuario...',
      duration: 3000,
      spinner: 'bubbles'
    }).then(a => {
      a.present().then(() => {
        console.log('presented');
        if (!this.isLoading) {
          a.dismiss().then(() => console.log('abort presenting'));
        }
      });
    });
    
  }

  addUserDynamics(registroUsuario: any){
    //this.SpinnerService.show();
    var datosUsuario:any = Object.values(registroUsuario);

    var nombre = datosUsuario[0].toUpperCase();
    var apellidos = datosUsuario[1].toUpperCase();
    var rfc = datosUsuario[2].toUpperCase();
    var email = datosUsuario[4].toUpperCase();

    if(rfc == '' || rfc == null ){
      rfc = "XAXX010101000";
    }

    var jsonUsuario = {
        "CustomerAccount": "",
        "OrganizationName": nombre+" "+apellidos,
        "SalesCurrencyCode": "MXN",
        "CustomerGroupId": "CONTADO",
        "SalesDistrict": "CHIH",
        "CompanyType": "2",
        "RFCNumber": rfc,
        "SiteId": "CHIH",
        "LineDiscountCode": "CHIH-D-BS",
        "SalesSegmentId": "COMERCIO",
        "PrimaryContactPhone": "",
        "PrimaryContactEmail": email,
        "Direcciones": [
        {
        "dataAreaId": "LIN",
        "customerLegalEntityId": "LIN",
        "customerAccountNumber": "",
        "addressDescription": "",
        "addressZipCode": "",
        "addressState": "",
        "addressCountryRegionId": "MEX",
        "addressCity": "",
        "addressStreet": "",
        "addressStreetNumber": "",
        "addressCountyId": "",
        "addressCountyName": "",
        "addressLocationRoles": "Business",
        "isPrimary": "Yes",
        "addressUniqueId": "New"
        }
        ],
        "Contactos": [
        {
        "partyNumber": "0",
        "description": nombre,
        "locator": "",
        "locatorExtension": "",
        "type": "1",
        "isPrimary": "Yes",
        "partyUniqueId": "New"
        },
        {
          "partyNumber": "0",
          "description": nombre,
          "locator": email,
          "locatorExtension": "",
          "type": "2",
          "isPrimary": "Yes",
          "partyUniqueId": "New"
          }
        ]
        }
    
      // console.log('Datos = ' + JSON.stringify(jsonUsuario));
      this.registroService.registroDynamics(JSON.stringify(jsonUsuario)).subscribe(dataUser => {
        console.log(dataUser);
        if(dataUser  == 1){
          this.datosDynamics = Object.values(dataUser);
          var  arrayDatos = JSON. parse(this.datosDynamics[2]);

          this.customerID = arrayDatos.CustomerAccount;
          this.correo = arrayDatos.PrimaryContactEmail;

          var datosJson = {
            'customerID' : this.customerID,
            'correo' : this.correo
          }

          this.registroService.actualizaCustomerID(JSON.stringify(datosJson)).subscribe(data=>{});
          //this.SpinnerService.hide();
          //this.exitoRegistro();
          
          this.router.navigate(['/login']);
        }
        
      });
  }

  get errorControl() {
    return this.regisForm.controls;
  }

  dismiss() {
    this.modalCtrl.dismiss();
  }
}
