import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Registro } from '../Models/registro.interface';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class RegistroService {

  constructor(
    public http: HttpClient
  ) { }

  registroUsuario(registro:Registro){
    console.log(registro);
    return this.http.post('https://lideart.com.mx/Automatizacion/apis/registro.php',registro);
  }

  registroDynamics(datosUsuario: any): Observable<any>{
    // return this.http.post('https://inax2.aytcloud.com/inaxapiv2/api/cliente/addClientdynamics365',datosUsuario,httpOption);
    return this.http.post('https://inax2.aytcloud.com/inaxapidev/api/cliente/addClientdynamics365',datosUsuario,httpOption);
  }

  actualizaCustomerID(datos: any): Observable<any> {

    return this.http.post('https://lideart.com.mx/Automatizacion/apis/actualizaCustomerID.php',datos);
  }

}

