import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SwiperModule } from 'swiper/angular';
import { AppComponent } from './app.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { AppRoutingModule } from './app-routing.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { HttpClientModule } from '@angular/common/http';
import { LoginPageModule } from './pages/login/login.module';
import { ModalRecuperarPageModule } from './pages/modal-recuperar/modal-recuperar.module';
import { ModalRegistroPageModule } from './pages/modal-registro/modal-registro.module';
import { DatePipe } from '@angular/common';
import { PayPal } from '@ionic-native/paypal/ngx';




@NgModule({
    declarations: [AppComponent],
    imports: [
        BrowserModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        LazyLoadImageModule,
        HttpClientModule,
        NgxPaginationModule,
        SwiperModule,
        LoginPageModule,
        ModalRecuperarPageModule,
        ModalRegistroPageModule,
        
        
    ],
    providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy},DatePipe,PayPal],
    bootstrap: [AppComponent]
})
export class AppModule {}
