import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Login } from '../Models/login.interface';
import { Usuario } from '../Models/usuario.interface';

const httpOption = {
  headers: new HttpHeaders({
    'Content-Type': 'application/json',
  }),
};

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  url: string = "https://lideart.com.mx/Automatizacion/apis/login.php";

  private usuarioSubject: BehaviorSubject<Usuario>;
  public usuario: Observable<Usuario>;

  public get usuarioData(): Usuario{
    return this.usuarioSubject.value;
  }

  constructor( public http: HttpClient ) { 
    this.usuarioSubject = new BehaviorSubject<Usuario>(JSON.parse(""+localStorage.getItem('usuario')));
    this.usuario = this.usuarioSubject.asObservable();
  }

  login(Login: Login): Observable<any> {
    return this.http.post<any>(this.url, Login, httpOption).pipe(
      map(res =>{
        if(res.exito === 1){
          const usuario: Usuario = {usuario : res.usuario, token: res.token};
          //console.log(usuario);
          localStorage.setItem('usuario', JSON.stringify(usuario));
          this.usuarioSubject.next(usuario);
        }
        return res;
      })
    );
  }

  logout(){
    localStorage.removeItem('usuario');
    this.usuarioSubject.next(null!);
  }

  checaUsuario(correo:string){
    return this.http.get<Response>('https://lideart.com.mx/Automatizacion/apis/checaUsuario.php?email='+correo);
  }

  recuperaDatosCliente(datosCorreo: any){
    return this.http.get<Response>('https://lideart.com.mx/Automatizacion/apis/recuperaDatosCliente.php?datosCorreo='+datosCorreo);
  }

  traePuntos(datosCorreo: any){
    return this.http.get<Response>('https://lideart.com.mx/Automatizacion/apis/traePuntos.php?datosCorreo='+datosCorreo);
  }
  
  actualizaDatosCliente(datosCorreo: any){
    return this.http.post('https://lideart.com.mx/Automatizacion/apis/actualizaDatosCliente.php',datosCorreo);
  }
  recuperaDatosDireccion(datosCorreo: any){
    return this.http.get<Response>('https://lideart.com.mx/Automatizacion/apis/recuperaDireccion.php?datosCorreo='+datosCorreo);
  }

  recuperaOrdenescliente(cliente: any){
    return this.http.get<Response>('https://inax2.aytcloud.com/inaxLideartExpoConsAPI/api/venta/getEspecificClientOrdenesVenta/'+cliente);
  }

  DetalledeVenta(OV: any){
    return this.http.get<Response>('https://inax2.aytcloud.com/inaxLideartExpoConsAPI/api/ProductosVenta/getLineasOrdenVenta/'+OV);
  }

  

  BorrarDatosDir(datosCorreo: any){
    return this.http.get<Response>('https://lideart.com.mx/Automatizacion/apis/BorrarDatosDir.php?datosCorreo='+datosCorreo);
  }

  selectDireccionZipCode(accion:string, postCode: string){
    return this.http.post<Response>('https://avanceytec.com.mx/Automatizacion/direcciones/address.php',{accion, postCode});
  }

  selectDireccionState(accion:string, state: string){
    return this.http.post<Response>('https://avanceytec.com.mx/Automatizacion/direcciones/address.php',{accion, state});
  }

  selectDireccionCity(accion:string, city: string){
    return this.http.post<Response>('https://avanceytec.com.mx/Automatizacion/direcciones/address.php',{accion, city});
  }
  enviaDireccion(direccion: any){
    
    return this.http.post('https://lideart.com.mx/Automatizacion/apis/registraDireccion.php',direccion);

  }

  checaCountyID(datosCorreo: any){
    return this.http.post('https://avanceytec.com.mx/Automatizacion/apis/checaCountyID.php',datosCorreo);
  }

  revisaDescuento(datosCorreo: any){
    return this.http.post('https://lideart.com.mx/Automatizacion/apis/checaCarrito.php',datosCorreo);
  }
  

  getReviews(){
    
    return this.http.get<Response>('https://lideart.com.mx/Automatizacion/apis/reviews.php');

  }

  registraReviews(datos:any=[]){
    return this.http.post<Response>('https://lideart.com.mx/Automatizacion/apis/registraReviews.php',datos);
  }
}
