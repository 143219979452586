import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { AutoLoginGuard } from './guards/auto-login.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'login',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () =>
      import('./pages/home/home.module').then((m) => m.HomePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'perfil/:rec',
    loadChildren: () =>
      import('./pages/perfil/perfil.module').then((m) => m.PerfilPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'modal-product',
    loadChildren: () =>
      import('./pages/modal-product/modal-product.module').then(
        (m) => m.ModalProductPageModule
      ),
    canLoad: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () =>
      import('./pages/login/login.module').then((m) => m.LoginPageModule),
    canLoad: [AutoLoginGuard]
  },
  {
    path: 'cart',
    loadChildren: () =>
      import('./pages/cart/cart.module').then((m) => m.CartPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'catalogo/:id_categoria',
    loadChildren: () =>
      import('./pages/catalogo/catalogo.module').then((m) => m.CatalogoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'modal-recuperar',
    loadChildren: () => import('./pages/modal-recuperar/modal-recuperar.module').then( m => m.ModalRecuperarPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'modal-registro',
    loadChildren: () => import('./pages/modal-registro/modal-registro.module').then( m => m.ModalRegistroPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'modal-descripcion-producto',
    loadChildren: () => import('./pages/modal-descripcion-producto/modal-descripcion-producto.module').then( m => m.ModalDescripcionProductoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'checkout',
    loadChildren: () => import('./pages/checkout/checkout.module').then( m => m.CheckoutPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'resumen-compra',
    loadChildren: () => import('./pages/resumen-compra/resumen-compra.module').then( m => m.ResumenCompraPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'categoria/:id_parent',
    loadChildren: () => import('./pages/categorias/categorias.module').then( m => m.CategoriasPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'categorias',
    loadChildren: () => import('./pages/tipos-categorias/tipos-categorias.module').then( m => m.TiposCategoriasPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'paquetes',
    loadChildren: () => import('./pages/categoria-por-paquetes/categoria-por-paquetes.module').then( m => m.CategoriaPorPaquetesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'modal-descripcion-paquetes',
    loadChildren: () => import('./pages/modal-descripcion-paquetes/modal-descripcion-paquetes.module').then( m => m.ModalDescripcionPaquetesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'catalogo-paquetes/:id_categoria',
    loadChildren: () => import('./pages/catalogo-paquetes/catalogo-paquetes.module').then( m => m.CatalogoPaquetesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'wishlist',
    loadChildren: () => import('./pages/wishlist/wishlist.module').then( m => m.WishlistPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'descripcion-producto/:id_product',
    loadChildren: () => import('./pages/descripcion-producto/descripcion-producto.module').then( m => m.DescripcionProductoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'descripcion-paquetes/:id_product',
    loadChildren: () => import('./pages/descripcion-paquetes/descripcion-paquetes.module').then( m => m.DescripcionPaquetesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'blog',
    loadChildren: () => import('./pages/blogsito/blogsito.module').then( m => m.BlogsitoPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'promociones',
    loadChildren: () => import('./pages/promociones/promociones.module').then( m => m.PromocionesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'articulo-blog/:id_blog',
    loadChildren: () => import('./pages/articulo-blog/articulo-blog.module').then( m => m.ArticuloBlogPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'lidepuntos',
    loadChildren: () => import('./pages/lidepuntos/lidepuntos.module').then( m => m.LidepuntosPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import('./pages/faq/faq.module').then( m => m.FaqPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'venta/:ov',
    loadChildren: () => import('./pages/resumen-venta/resumen-venta.module').then( m => m.ResumenVentaPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'reviews',
    loadChildren: () => import('./pages/reviews/reviews.module').then( m => m.ReviewsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'chat',
    loadChildren: () => import('./pages/chat/chat.module').then( m => m.ChatPageModule)
  },
  

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
